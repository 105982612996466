const getOpeningHours = async (department) => {
	const response = await fetch(`wp-json/brut/v1/is-open/${department}`);
	const data = await response.json();

	return data;
}

document.addEventListener('DOMContentLoaded', async () => {
	const openingHoursContainers = document.querySelectorAll('.is-open');

	if (!openingHoursContainers.length) {
		return;
	}

	for (const container of openingHoursContainers) {
		const departmentName = container.dataset.department;
		const statusContainer = container.querySelector('.status');

		try {
			const openingHours = await getOpeningHours(departmentName);
			const goingToOpen = ! openingHours.is_open && false !== openingHours.opens_at;
			const openTomorrow = ! openingHours.is_open && true === openingHours.is_open_tomorrow;
			let additionalInfo = '';

			if (goingToOpen) {
				additionalInfo = ` ${brut_variables.translation_opens_today_at} ${openingHours.opens_at}`;
			} else if (openTomorrow) {
				additionalInfo = ` ${brut_variables.translation_open_tomorrow}`;
			}
			
			const additionalString = additionalInfo.length ? `<span class="text-[14px] text-gray-dark">${additionalInfo}</span>` : '';
			
			const html = openingHours.is_open ? 
			`<p class="text-[14px] text-gray-dark"><span class="font-bold uppercase text-success">${brut_variables.translation_open}</span> ${brut_variables.translation_until} ${openingHours.is_open_until}</p>`
				:
			`<p class="text-[14px]"><span class="font-bold uppercase text-danger">${brut_variables.translation_closed}</span>${additionalString}</p>`;
			
			statusContainer.innerHTML = html;
		} catch (error) {
			console.error(`Error fetching hours for ${departmentName}:`, error);
		}
	}
});
